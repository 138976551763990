#root {
    position: relative;
    min-height: 90vh;
}

#content-wrap {
    padding-bottom: 2.5rem;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Myriad Pro', Fallback, sans-serif;
    background: rgb(0, 0, 0);
}

body, a {
    color: #f0f2f5;
}

div {
    font-size: 18px;
}

h1 {
    text-align: center;
    font-size: 2em;
    color: #e09900;
}

button {
    padding: 0.35em 1.2em;
    margin: 1em 0.3em 0.3em 0;
    border-radius: 0.12em;
    box-sizing: border-box;
    text-decoration: none;
    font-weight: 300;
    color: #FFFFFF;
    text-align: center;
    transition: all 0.2s;
    background: #f15b2a;
    border-color: #f15b2a;
    line-height: 1.7em;
    font-size: 20px;
}

button:hover {
    color: #000000;
    background: #f07850;
}

h2 {
    text-align: center;
    font-size: 1.5em
}

.content {
    padding: 0 20px;
    margin-top: 3em;
}

.page-content {
    padding: 24px;
    min-height: 280px;
    margin: 16px 0;
}

.footer {
    left: 0;
    bottom: 0;
    width: 100%;
    color: white;
    text-align: center;
    position: absolute;
    height: 2.5rem;
}

div.footer-content {
    font-size: 0.7em;
}

.footer-content {
    display: block;
}

.column {
    width: 100%;
    text-align: center;
}

.full-width {
    width: 100%;
    text-align: center;
}

.bold {
    font-weight: bold;
}

.flag-container {
    z-index: 1000;
    position: fixed;
    top: 15px;
    right: 50px;
    width: 2em;
}

.flag-container .column {
    text-align: left;
}

.flag-container svg {
    border: 2px;
    margin: 2px;
}

.hidden {
    display: none;
}

@media only screen and (min-width: 1024px) {
    .left .block {
        direction: rtl;
    }
    .block {
        display: flex;
        align-items: center;
    }
    div {
        font-size: 16px;
    }
    .flag-container {
        margin-top: 28px;
        right: 5px;
        top: 0;
    }
    .page-content {
        padding-top: 4em;
    }
}