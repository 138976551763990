.input-field {
    width: 100%;
    border: 1px solid #565656;
    height: 40px;
    font-size: 1em;
}

textarea.input-field {
    vertical-align: top;
    height: 100px;
}

.has-error {
    border: solid 2px red;
}

.form-field {
    padding-bottom: 20px;
    vertical-align: top;
    text-align: left;
    padding-left: 10px;
}

.form-field label {
    padding-right: 10px;
}

.form-field .column {
    text-align: left;
}

.error-panel {
    border: 2px solid red;
    margin-bottom: 1em;
    float: right;
    width: 94%;
}

.column-contact {
    padding-bottom: 10%;
    text-align: left;
}

.g-recaptcha div {
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    transform: scale(0.77);
    -webkit-transform: scale(0.77);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
}

.error {
    color: red;
}

@media only screen and (min-width: 1024px) {
    .contact-form {
        text-align: left;
        float: right;
    }

    .contact-form button {
        float: right;
        margin-left: 20px;
    }
    
    .g-recaptcha div {
        margin-left: auto;
        margin-right: auto;
        transform: scale(1);
        -webkit-transform: scale(1);
        transform-origin: 0 0;
        -webkit-transform-origin: 0 0;
    }

    .column-contact {
        padding: 0 0 10% 10%;
    }

    .error-panel {
        width: 40%;
    }
}