.row-separator {
    display: none;
}

/* Clearfix (clear floats) */
.block::after {
    content: "";
    clear: both;
    display: table;
  }

@media only screen and (min-width: 1024px) {
    .page-subtitle{
        text-align: center;
    }
    .column {
        float: left;
        width: 50%;
    }
    
    .column img {
        display: block;
        margin: auto;
    }

    .row-separator {
        display: inherit;
        text-align: center;
    }
}

