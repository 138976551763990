.logo-heading img,
.logo-heading div {
    padding: 10px;
    float: left;
    max-width: 15vw;
    min-width: 100px;
}

.header {
    background-color: black;
    box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, .1);
    position: fixed;
    width: 100%;
    z-index: 3;
    top: 0;
}

.header ul {
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
    background-color: black;
}

.header li a {
    display: block;
    padding: 20px 20px;
    text-decoration: none;
    margin-top: 20px;
}

.home-link {
    color: #e09900;
}

.header li a:hover,
.header .menu-btn:hover {
    background-color: #1e1d1d;
}

.header .logo {
    display: block;
    float: left;
    font-size: 2em;
    padding: 10px 20px;
    text-decoration: none;
}

.header .menu {
    clear: both;
    max-height: 0;
    transition: max-height .2s ease-out;
}


.header .menu-icon {
    cursor: pointer;
    display: inline-block;
    float: right;
    padding: 28px 20px;
    position: relative;
    user-select: none;
}

.header .menu-icon .navicon {
    background: #333;
    display: block;
    height: 2px;
    position: relative;
    transition: background .2s ease-out;
    width: 18px;
}

.header .menu-icon .navicon:before,
.header .menu-icon .navicon:after {
    background: #333;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    transition: all .2s ease-out;
    width: 100%;
}

.header .menu-icon .navicon:before {
    top: 5px;
}

.header .menu-icon .navicon:after {
    top: -5px;
}

.header .menu-btn,
.menu-btn-checked {
    display: none;
}

.header .menu-btn-checked~.menu {
    max-height: fit-content;
}

.header .menu-btn-checked~.menu-icon .navicon {
    background: transparent;
}

.header .menu-btn-checked~.menu-icon .navicon:before {
    transform: rotate(-45deg);
}

.header .menu-btn-checked~.menu-icon .navicon:after {
    transform: rotate(45deg);
}

.header .menu-btn-checked~.menu-icon:not(.steps) .navicon:before,
.header .menu-btn-checked~.menu-icon:not(.steps) .navicon:after {
    top: 0;
}

@media only screen and (min-width: 1024px) {
    .header {
        margin: 0 auto;
    }
    .header .header ul {
        background-color: transparent;
    }
    .header li {
        float: left;
    }
    .header .menu {
        clear: none;
        max-height: none;
        padding-left: 7%;
    }
    .header .menu-icon {
        display: none;
    }
}

@media only screen and (max-height: 500px) {
    .header li a {
        padding: 5px 20px;
        margin-top: 10px;
    }
}