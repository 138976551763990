.react-cookie-law-dialog {
    bottom: 0px;
    top: auto !important;
    background-color: rgb(29, 27, 27) !important;
    padding: 10px;
    text-align: right;
}

.react-cookie-law-msg {
    color: white !important;
    text-align: left;
}

.react-cookie-law-policy {
    color: white !important;
    white-space: nowrap;
}

.react-cookie-law-button-wrapper {
    float: none !important;
    display: inline-block;
    margin-left: 10px;
}

.react-cookie-law-dialog label {
    font-size: 10pt !important;
    padding: 3px 0 0 2px !important;
}

.react-cookie-law-option-wrapper {
    top: 1px;
    float: none !important;
}

.react-cookie-law-option-checkbox {
    display: inline-block !important;
    top: 0px !important;
    left: -20px !important;
}

.react-cookie-law-select-pane {
    display: inline-block !important;
}

.react-cookie-law-accept-btn {
    margin-top: 0;
}