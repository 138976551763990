.home-container {
    background-position: top center;
    background-color: #000000;
    background-image: url('/static/jpg/softtwining-home-new.jpg');
    background-repeat: no-repeat;
    min-height: 1024px;
    text-align: center;
}

.home-text {
    text-align: left;
    position: absolute;
    top: 20%;
    left: 45%;
    transform: translate(-50%, -50%);
    font-size: 18px;
    font-weight: 500;
}

.home-text h1 {
    text-align: left;
    font-weight: 700;
    color: #e09900!important;
    font-size: 1.8em;
}

@media only screen and (min-width: 1024px) {
    .home-container {
        background-size: 100%;
        width: 90%;
        margin: 0 auto;
    }
    
    .home-text {
        max-width: 42%;
        left: 35%;
    }
}